import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import get from 'lodash/get'

Vue.use(Router)

const _ = { get }

const templateFolder = store.getters['microsite/template']
const routerPaths = store.getters['microsite/routerPaths']

const subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : ''

let routeArray

if (subdomain === process.env.VUE_APP_ORDER_SUBDOMAIN) {
  routeArray = [
    {
      path: '/',
      name: 'Order',
      component: () => import(/* webpackChunkName: "generic-page" */ '@/open-order/pages/OrderFormPage.vue')
    }, 
    {
      path: '*',
      component: () => import(/* webpackChunkName: "layout-error" */ `@/layouts/${templateFolder}/ErrorLayout.vue`),
      children: [{
        path: '',
        name: 'error',
        component: () => import(/* webpackChunkName: "error" */ `@/pages/${templateFolder}/error/NotFoundPage.vue`)
      }]
    }
  ]
} else {
  routeArray = [{
    path: '/',
    component: () => import(/* webpackChunkName: "layout-default" */ `@/layouts/${templateFolder}/DefaultLayout.vue`),
    children: [{
      path: routerPaths['Home'],
      name: 'Home',
      component: () => import(/* webpackChunkName: "generic-page" */ `@/pages/${templateFolder}/GenericPage.vue`)
    },
    {
      path: routerPaths['AboutUs'],
      name: 'AboutUs',
      component: () => import(/* webpackChunkName: "generic-page" */ `@/pages/${templateFolder}/GenericPage.vue`)
    },
    {
      path: routerPaths['Programs'],
      name: 'Programs',
      component: () => import(/* webpackChunkName: "generic-page" */ `@/pages/${templateFolder}/ProgramPage.vue`)
    },
    {
      path: routerPaths['WhyDamage'],
      name: 'WhyDamage',
      component: () => import(/* webpackChunkName: "generic-page" */ `@/pages/${templateFolder}/GenericPage.vue`)
    },
    {
      path: routerPaths['WhyTi'],
      name: 'WhyTi',
      component: () => import(/* webpackChunkName: "generic-page" */ `@/pages/${templateFolder}/GenericPage.vue`)
    },
    {
      path: routerPaths['WhyMc'],
      name: 'WhyTi',
      component: () => import(/* webpackChunkName: "generic-page" */ `@/pages/${templateFolder}/GenericPage.vue`)
    },
    {
      path: routerPaths['WhyRain'],
      name: 'WhyRain',
      component: () => import(/* webpackChunkName: "generic-page" */ `@/pages/${templateFolder}/GenericPage.vue`)
    },
    {
      path: routerPaths['Support'],
      name: 'Support',
      component: () => import(/* webpackChunkName: "generic-page" */ `@/pages/${templateFolder}/GenericPage.vue`)
    },
    {
      path: routerPaths['Faq'],
      name: 'Faq',
      component: () => import(/* webpackChunkName: "faq-page" */ `@/pages/${templateFolder}/FaqPage.vue`)
    },
    {
      path: routerPaths['ContactUs'],
      name: 'ContactUs',
      component: () => import(/* webpackChunkName: "contact-page" */ `@/pages/${templateFolder}/ContactPage.vue`)
    },
    {
      path: 'manage-coverage',
      name: 'ManageCoverage',
      component: () => import(/* webpackChunkName: "manage-coverage-page" */ `@/pages/${templateFolder}/ManageCoveragePage.vue`)
    }]
  }, 
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "order-form-page" */ '@/open-order/pages/OrderFormPage.vue')
  }, 
  {
    path: '*',
    component: () => import(/* webpackChunkName: "layout-error" */ `@/layouts/${templateFolder}/ErrorLayout.vue`),
    children: [{
      path: '',
      name: 'error',
      component: () => import(/* webpackChunkName: "error" */ `@/pages/${templateFolder}/error/NotFoundPage.vue`)
    }]
  }]
}

export const routes = routeArray 

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  // paths that are always active
  const overridePaths = ['/', '/order', '/manage-coverage']

  if (!overridePaths.includes(to.path)) {

    const toPage = store.state.microsite.pages.find((page) => to.path === page.URLPath.toLowerCase().slice(0, -1))
    
    if ((!toPage || !toPage.IsActive) && to.name !== 'error' ) {
      return next({ name: 'error' })
    }
  }
  store.commit('microsite/setPath', to.path)

  if (to.path !== '/order') {
    const title = _.get(store.getters['microsite/currentPage'], 'Title', null)

    document.title = `Travel Protection${title ? ` | ${title}` : ''}`
  }

  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
