import qs from 'query-string'

export const sendRequest = async (path, type = 'GET', params = {}, headers = {}) => {
    type = type.toUpperCase()

    let subdomain
    let token
    let queryParams = ''
    let requestBody = null
    let responseBody = null
    
    // default to GET if not a valid request types
    const requestType = ['GET', 'POST', 'PUT', 'DELETE'].includes(type) ? type : 'GET'

    subdomain =  window.location.host.split('.')[1] ? window.location.host.split('.')[0] : process.env.VUE_APP_DEFAULT_RESELLER
    if (subdomain === 'microsite') {
        subdomain = process.env.VUE_APP_DEFAULT_RESELLER
    }

    // if we're going through the order subdomain, we look at the query string
    if (subdomain === process.env.VUE_APP_ORDER_SUBDOMAIN) {
        const parsedParams = qs.parse(location.search)

        if (parsedParams.token) {
            token = parsedParams.token
            subdomain = null
        } else if (parsedParams.subdomain) {
            subdomain = parsedParams.subdomain
        } else {
            subdomain = process.env.VUE_APP_DEFAULT_RESELLER
        }
    }

    const combinedParams = { ...params, ...(subdomain && { subdomain }) }
    const combinedHeaders = { ...headers, ...(token && { token }) }

    // if request is GET or DELETE make params as query string
    if (type === 'GET' || type === 'DELETE') {
        queryParams = `?${qs.stringify(combinedParams)}`
    // if request is POST or PUT put the params in the request body
    } else {
        requestBody = combinedParams
    }
    
    // If the default backend path is not set, dont send the request
    if (process.env.VUE_APP_OFFLINE === 'false' && process.env.VUE_APP_API_URL) {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/${path}${queryParams}`, {
            method: requestType,
            headers: { 'Content-Type': 'application/json', ...combinedHeaders },
            body: requestBody ? JSON.stringify(requestBody) : null
        }).catch(() => {
            // TODO: figure how best way to handle error
        })

        try {
            responseBody = await response.json()
        } catch {
            responseBody = null
        }        
    }

    return responseBody

}