import qs from 'query-string'
import get from 'lodash/get'
import { sendRequest } from '@/configs/requests'

// default themes if nothing returned
const defaultTheme = 
{
  primary: '#0096c7',
  secondary: '#2a4f66'
}

const _ = { get }

export default {
  namespaced: true,
  state: {
    meta: null,
    path: null,
    pages: [],
    faqs: [],
    products: [],
    isLoading: {
      main: false,
      faqs: false,
      products: false
    }
  },
  getters: {
    template(state) {
      return state.meta.attributes.template
    },
    theme(state) {
      const primary = state.meta.attributes.PrimaryColor || 'Blue'

      return {
        primary,
        secondary: '#424242'
      }
    },
    subdomain(state) {
      return state.meta.subdomain
    },
    currentPage(state) {
      const currentPage = state.pages.find((page) => {
        if (state.path === '/') {
          return page.Id === 1
        }

        return page.URLPath.toLowerCase().slice(0, -1) === state.path
      })

      return currentPage
    },
    menu(state) {
      const pages = state.pages
      .filter((page) => (page.IsActive || page.code === 'Home') && page.Id === page.ParentId)
      .map((page) => ({
        Id: page.Id,
        title: page.Title,
        link: page.URLPath === 'Home' ? '/' : page.URLPath.toLowerCase().slice(0, -1)
      }))

      pages.forEach((page) => {
        page.childPages = state.pages
        .filter((otherPage) => otherPage.IsActive && page.Id === otherPage.ParentId && page.Id !== otherPage.Id)
        .map((childPage) => ({
          Id: childPage.Id,
          title: childPage.Title,
          link: childPage.URLPath === 'Home' ? '/' : childPage.URLPath.toLowerCase().slice(0, -1)
        }))
      })

      return pages
    },
    currentBanner(state, getters) {
      // object that contains all dynamic paths for each image
      const publicPath = `/${process.env.VUE_APP_DEFAULT_MICROSITE_PUBLIC_FOLDER}`
      const currentPageImage  = _.get(getters.currentPage, 'PageData[0].BannerImage', null)

      return {
        banner: currentPageImage ? `${publicPath}${currentPageImage}` : null,
        title: _.get(getters.currentPage, 'BannerTitle', null)
      }
    },
    routerPaths(state) {
      // maps route name to path
      return state.pages.reduce((acc, page) => {
        let path = page.URLPath.toLowerCase()

        if (page.Code === 'Home') {
          path = ''
        }

        return { ...acc, [page.Code]: path }
      }, {})
    }
  },
  mutations: {
    setMeta(state, meta) {
      state.meta = meta
    },
    setPath(state, path) {
      state.path = path
    },
    setPages(state, pages) {
      state.pages = pages
    },
    setProducts(state, products) {
      state.products = products
    },
    setLoading(state, isLoading) {
      state.isLoading = {
        ...state.isLoading,
        ...isLoading
      }
    },
    setFaqs(state, faqs) {
      state.faqs = faqs
    },
    setLoadingFaqs(state, isLoadingFaqs) {
      state.isLoadingFaqs = isLoadingFaqs 
    },
    setLoadingProducts(state, isLoadingProducts) {
      state.isLoadingProducts = isLoadingProducts 
    }
  },
  actions: {
    async getMeta( { commit } ) {

      commit('setLoading', { main: true })

      // eslint-disable-next-line prefer-const
      let [response, pageResponse] = await Promise.all([
        sendRequest('microsite', 'GET'),
        sendRequest('microsite/pages', 'GET')])

      if (!response) {
        response = {
          attributes: defaultTheme
        }
      }
      // TODO add template to attributes
      response.attributes.template = 'default'

      const subdomain = response.custom_url ? response.custom_url.split(' ')[0] : null

      commit('setMeta', { ...response, subdomain })
      commit('setPages', pageResponse)
      commit('setLoading', { main: false })
    },
    async getFaqs( { commit } ) {

      commit('setLoading', { faqs: true })
      const response  = await sendRequest('microsite/faqs', 'GET')

      commit('setFaqs', response)
      commit('setLoading', { faqs: false })
    },
    async getProducts( { commit } ) {

      commit('setLoading', { products: true })
      const response  = await sendRequest('microsite/programs', 'GET')

      commit('setProducts', response)
      commit('setLoading', { products: false })
    }
  }
}
