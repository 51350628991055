import qs from 'query-string'
import isEmpty from 'lodash/isEmpty'
import { format } from 'date-fns'
import { sendRequest } from '@/configs/requests'

const _ = { isEmpty }

export default {
  namespaced: true,
  
  state: {
    order: {},
    quote: {},
    policy: {},
    loading: {
      quote: false,
      payment: false
    },
    errors: {
      payment: null,
      quote: null
    }
  },
  
  getters: {
    isTravel(state) {
      return ['CFAR', 'TI'].includes(state.order.coverage_type)
    },
    isManager(state, getters, rootState) {
      return !!(getters.token && rootState.microsite.meta)
    },
    token() {
      return qs.parse(location.search).token
    }
  },
  
  mutations: {
    setOrder(state, order) {
      state.order = order
    },
    setQuote(state, quote) {
      state.quote = quote
    },
    setPolicy(state, policy) {
      state.policy = policy
    },
    setSelectedCountry(state, selectedCountry) {
      state.selectedCountry = selectedCountry
    },
    setStatesFrom(state, statesFrom) {
      state.statesFrom = statesFrom
    },
    setStatesTo(state, statesTo) {
      state.statesTo = statesTo
    },
    setErrors(state, errors) {
      state.errors = {
        ...state.errors,
        ...errors
      }
    },
    setLoading(state, isLoading) {
      state.loading = {
        ...state.loading,
        ...isLoading
      }
    }
  },
  
  actions: {
    updateOrder({ commit, state }, data) {
      //If no data has been added to the data Object, overwrite it
      if (state.order === {}) {
        commit('setOrder', data)
      }
      //If there is already data in the data Object, merge the new data into it
      else {
        const order = { ...state.order, ...data }

        commit('setOrder', order)
      }
    },

    async getQuote({ commit, state, getters, rootState, rootGetters }) {
      const order = { ...state.order }

      const locationData = { ...rootGetters['fieldData/formatLocations'] }
      const countryFrom = locationData.countries[order.country_from].A2
      const countryTo = locationData.countries[order.country_to].A2
      const stateFrom = locationData.stateFrom[order.state_from] ? locationData.stateFrom[order.state_from].a2 : null
      const stateTo = locationData.stateTo[order.state_to] ? locationData.stateTo[order.state_to].a2 : null

      const body = {
        subdomain: rootState.microsite.meta.subdomain,
        coverage_type: order.coverage_type,
        country_to: countryTo,
        country_from: countryFrom,
        state_to: stateTo,
        state_from: stateFrom,
        coverage_amount: order.coverage_amount,
        start_date: format(new Date(order.start_date), 'yyyy-MM-dd'),
        end_date: format(new Date(order.end_date), 'yyyy-MM-dd')
      }

      // reset error incase of any previous request attempt
      commit('setErrors', { quote: null })
      commit('setLoading', { quote: true })
      const headers = getters.isManager ? { token: getters.token } : {}
      const response = await sendRequest('order/quote', 'POST', body, headers)

      if (_.isEmpty(response)) {
        commit('setErrors', { quote: 'Coverage Not Found' })
      }

      commit('setQuote', response)
      commit('setLoading', { quote: false })
    },
    async processPayment({ commit, state, getters, rootGetters }, data) {
      const order = { ...state.order }

      order.start_date = format(new Date(order.start_date), 'yyyy-MM-dd')
      order.end_date = format(new Date(order.end_date), 'yyyy-MM-dd')
      order.booking_date = format(new Date(order.booking_date), 'yyyy-MM-dd')

      const locationData = { ...rootGetters['fieldData/formatLocations'] }

      order.country_from = locationData.countries[order.country_from].A2
      order.country_to = locationData.countries[order.country_to].A2
      order.state_from = locationData.stateFrom[order.state_from] ? locationData.stateFrom[order.state_from].a2 : null
      order.state_to = locationData.stateTo[order.state_to] ? locationData.stateTo[order.state_to].a2 : null

      const body = {
        ...order,
        ...data
      }

      // reset error incase of any previous request attempt
      commit('setErrors', { payment: null })
      commit('setLoading', { payment: true })
      const headers = getters.isManager ? { token: getters.token } : {}

      console.log(body)
      const response = await sendRequest('order/open', 'POST', body, headers)

      if (response.error) {
        commit('setErrors', { payment: response.error })
      } else {
        commit('setPolicy', response)
      }
      commit('setLoading', { payment: false })
    }

  },
  async openPolicy({ commit, state }, data) {
    const { order } = state

    const body = {
      ...order,
      ...data
    }

    // reset error incase of any previous request attempt
    commit('setErrors', { payment: null })
    commit('setLoading', { payment: true })
    const response = await sendRequest('order/open', 'POST', body)

    if (response.error) {
      commit('setErrors', { payment: response.error })
    } else {
      commit('setPolicy', response)
    }
    commit('setLoading', { payment: false })
  }

}