import { sendRequest } from '@/configs/requests'

export default {
  namespaced: true,
  
  state: {
    coverage: null,
    errorMessage: null,
    isLoading: false
  },
  getters: {},
  mutations: {
    setCoverage(state, coverage) {
      state.coverage = coverage
    },
    setErrorMessage(state, errorMessage) {
      state.errorMessage = errorMessage
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    }
  },
  
  actions: {
    async getCoverage({ commit, rootGetters }, data) {
      commit('setErrorMessage', null)
      commit('setIsLoading', true)

      const subdomain = rootGetters['microsite/subdomain']

      let coverage

      try {
        coverage = await sendRequest('policy', 'POST', { ...data, ...{ subdomain } })
      } catch {
        coverage = { errors: '' }
      }

      commit('setIsLoading', false)
      if (coverage.errors) {
        commit('setErrorMessage', 'Policy not found')
      } else {
        // add the user input to the object for later requests
        commit('setCoverage', { ...coverage, ...data })
      }
    }
  }
}