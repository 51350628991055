<template>
  <v-app>
    <v-overlay v-if="isLoading.main" color="rgba(0,0,0,0.4)">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <router-view v-if="!isLoading.main" />
  </v-app>
</template>

<script>
import config from './configs'
import { mapState, mapGetters } from 'vuex'

/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
*/
export default {
  computed: {
    ...mapState({
      isLoading: (state) => state.microsite.isLoading
    }),
    ...mapGetters({
      theme: 'microsite/theme'
    })
  },
  created() {
    this.$vuetify.theme.themes.light.primary = this.theme.primary
    this.$vuetify.theme.themes.light.secondary = this.theme.secondary
  },
  head: {
    link: [
      // adds config/icons into the html head tag
      ...config.icons.map((href) => ({ rel: 'stylesheet', href }))
    ]
  }
}
</script>
