import { sendRequest } from '@/configs/requests'

export default {
  namespaced: true,
  
  state: {
    countries: [],
    statesFrom: [],
    statesTo: [],
    
    // since country/states are shared between steps, we keep track of the values here
    coverageLocations: {
      country_from: null,
      country_to: null,
      state_from: null,
      state_to: null
    }
  },
  
  getters: {
    formatLocations(state) {
      const result = { countries: {}, stateFrom: {}, stateTo: {} }

      result.countries = state.countries.reduce((acc, curr) => {
        acc[curr.CountryId] = curr

        return acc
      }, {})

      result.stateFrom = state.statesFrom.reduce((acc, curr) => {
        acc[curr.stateid] = curr

        return acc
      }, {})

      result.stateTo = state.statesTo.reduce((acc, curr) => {
        acc[curr.stateid] = curr

        return acc
      }, {})

      return result
    }
  },
  
  mutations: {
    setCountries(state, countries) {
      state.countries = countries
    },
    setStatesFrom(state, statesFrom) {
      state.statesFrom = statesFrom
    },
    setStatesTo(state, statesTo) {
      state.statesTo = statesTo
    },
    setCoverageLocations(state, coverageLocations) {
      state.coverageLocations = coverageLocations
    }
  },
  
  actions: {
    async getCountries({ commit }) {
      const countries = await sendRequest('countries')

      commit('setCountries', countries)
    },
    async getStates({ commit }, data) {
      const { countryId, stateSet } = data

      if (countryId) {
        const states = await sendRequest(`countries/${countryId}/states`);

        commit(`set${stateSet}`, states);
      }
    },
    updateCoverageLocations({ commit, state }, data) {
      // override value(s) currently in location object
      const locations = { ...state.coverageLocations, ...data }

      commit('setCoverageLocations', locations)

    }

  }

}