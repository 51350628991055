import Vue from 'vue'
import App from './App.vue'

// VUEX - https://vuex.vuejs.org/
import store from './store'

// Need to get meta before defining routes
// import router from './router'

// PLUGINS
import vuetify from './plugins/vuetify'
import './plugins/vuetify-money.js'
import './plugins/vue-head'
import './plugins/vue-mask'
// import './plugins/vue-gtag'

// FILTERS
import './filters/capitalize'
import './filters/lowercase'
import './filters/uppercase'
import './filters/placeholder'
import './filters/trim'
import './filters/formatDate'

// STYLES
// Main Theme SCSS
import './assets/scss/theme.scss'
// Custom CSS
import './assets/css/vendor-custom.css'

export default (async  () => {
  
  // Get meta before defining routes
  if (store.state.microsite.meta === null) {
    await store.dispatch('microsite/getMeta')
  }
  
  // Since we have meta and the template now, we can import router with our dynamic component selection 
  const router = require('./router').default

  // Set this to false to prevent the production tip on Vue startup.
  Vue.config.productionTip = false

  /*
  |---------------------------------------------------------------------
  | Main Vue Instance
  |---------------------------------------------------------------------
  |
  | Render the vue application on the <div id="app"></div> in index.html
  |
  | https://vuejs.org/v2/guide/instance.html
  |
  */

  return  new Vue({
    vuetify,
    router,
    store,
    render: (h) => h(App)
  }).$mount('#app')

})()
